import {
    Backdrop,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core"
import WorkerIcon from "@material-ui/icons/Person"
import "chart.js"
import React, { useEffect, useMemo, useState } from "react"
// @ts-ignore
import { LineChart } from "react-chartkick"
import ReactMapboxGl, { Feature, Layer } from "react-mapbox-gl"
import { useParams } from "react-router-dom"
import { history } from "../../App"
import Product from "../../models/Product"
import fetchProductInfo from "../../remote/RestApiFetchProductInfo"
import ProductAppBar from "./components/ProductAppBar"
import useStyles from "./styles/ProductStyles"

const Map = ReactMapboxGl({
    accessToken:
        "pk.eyJ1IjoibG9iZWxsb21hdHRlbyIsImEiOiJja2FmdGtjZjYwMDE4MnNzMXA2aXJic3BxIn0.DY36VUve1OOpGTZoX5JplQ",
})

export default function () {
    const classes = useStyles()

    const params: any = useParams()
    const productId = params.productId

    const [isLoading, setIsLoading] = useState(true)
    const [product, setProduct] = useState<Product>()

    const chartData = useMemo(() => {
        let temperatureData: any = {}
        product?.weatherAnalysis?.forEach((weatherCondition: any) => {
            temperatureData[
                new Date(weatherCondition.timestamp).toISOString()
            ] = weatherCondition.temperature
        })

        let humidityData: any = {}
        product?.weatherAnalysis?.forEach((weatherCondition: any) => {
            humidityData[new Date(weatherCondition.timestamp).toISOString()] =
                weatherCondition.humidity
        })

        return [
            {
                name: "Temperature (°C)",
                data: temperatureData,
            },
            {
                name: "Humidity (%)",
                data: humidityData,
            },
        ]
    }, [product])

    useEffect(() => {
        setIsLoading(true)

        fetchProductInfo(productId)
            .then((product: Product) => {
                setIsLoading(false)
                setProduct(product)
            })
            .catch((err) => {
                setIsLoading(false)
                window.alert(err)
            })
    }, [productId])

    return (
        <>
            <ProductAppBar onBackPress={() => history.goBack()} />
            {isLoading || product == null ? (
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <Container maxWidth="lg" className={classes.root}>
                    <div style={{ height: 56 }} />
                    <Paper
                        className={classes.productImageWrapper}
                        elevation={3}
                    >
                        <img
                            className={classes.productImage}
                            src={product.imageUrl}
                        />
                    </Paper>
                    <Typography
                        variant="h2"
                        component="h2"
                        className={classes.productName}
                    >
                        {product?.name}
                    </Typography>
                    <Typography
                        variant="h4"
                        component="h4"
                        className={classes.subtitle}
                    >
                        Lotto #{product?.batchNumber}
                    </Typography>
                    <hr style={{ marginTop: 44, marginBottom: 44 }} />
                    <Typography
                        variant="h3"
                        component="h3"
                        className={classes.productName}
                    >
                        Mappa del Prodotto
                    </Typography>
                    <Paper elevation={2}>
                        <Map
                            style="mapbox://styles/mapbox/streets-v9"
                            center={[
                                Number(product.location!!.latitude),
                                Number(product.location!!.longitude),
                            ]}
                            zoom={[12]}
                            bearing={[20]}
                            pitch={[40]}
                            containerStyle={{
                                height: "400px",
                                width: "100%",
                                marginTop: 44,
                            }}
                        >
                            <Layer
                                type="circle"
                                paint={{
                                    "circle-opacity": 0.75,
                                    "circle-stroke-width": 1,
                                    "circle-radius": 40,
                                    "circle-color": "#000000",
                                }}
                            >
                                <Feature
                                    coordinates={[
                                        Number(product.location!!.latitude),
                                        Number(product.location!!.longitude),
                                    ]}
                                />
                            </Layer>
                        </Map>
                    </Paper>
                    <hr style={{ marginTop: 64, marginBottom: 44 }} />
                    <Typography
                        variant="h3"
                        component="h3"
                        className={classes.productName}
                    >
                        Lavoratori
                    </Typography>
                    <Typography
                        variant="h4"
                        component="h4"
                        className={classes.subtitle}
                    >
                        {product?.workers?.length}{" "}
                        {product?.workers?.length != null &&
                        product?.workers?.length == 1
                            ? "persona"
                            : "persone"}{" "}
                        hanno lavorato a questo prodotto
                    </Typography>
                    <Grid container spacing={3} style={{ marginTop: 44 }}>
                        {product?.workers?.map((worker: any) =>
                            worker == null ? null : (
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    className={classes.workerInfo}
                                >
                                    <WorkerIcon
                                        className={classes.workerImage}
                                    />
                                    <Typography
                                        variant="h5"
                                        component="h5"
                                        className={classes.workerAge}
                                    >
                                        Lavoratore #{worker.id}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        className={classes.workerAge}
                                    >
                                        {worker.age} anni
                                    </Typography>
                                </Grid>
                            )
                        )}
                    </Grid>
                    <hr style={{ marginTop: 44, marginBottom: 44 }} />
                    <Typography
                        variant="h3"
                        component="h3"
                        className={classes.weatherConditions}
                    >
                        Condizioni Meteo
                    </Typography>
                    <LineChart
                        width="100%"
                        height="500px"
                        data={chartData}
                        download={(
                            product!!.name!! + "-Weather-Analysis"
                        ).replace(" ", "_")}
                    />
                    <hr style={{ marginTop: 44, marginBottom: 44 }} />
                    <Typography
                        variant="h3"
                        component="h3"
                        className={classes.relatedProducts}
                    >
                        Prodotti Correlati
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        variant="h4"
                        component="h4"
                    >
                        {product?.related?.filter((p) => p != null).length ?? 0}{" "}
                        prodott
                        {product?.related?.length == 1 ? "o" : "i"} correlat
                        {product?.related?.length == 1 ? "o" : "i"}
                    </Typography>
                    <Grid container spacing={3} style={{ marginTop: 36 }}>
                        {product?.related?.map((product: any) =>
                            product == null ? null : (
                                <Grid
                                    item
                                    xs={4}
                                    sm={3}
                                    md={2}
                                    className={classes.relatedProductInfo}
                                    onClick={() =>
                                        history.push(`/product/${product.id}`)
                                    }
                                >
                                    <Paper
                                        elevation={3}
                                        className={
                                            classes.relatedProductImageContainer
                                        }
                                    >
                                        <img
                                            src={product.imageUrl}
                                            className={
                                                classes.relatedProductImage
                                            }
                                        />
                                    </Paper>
                                    <Typography
                                        variant="h6"
                                        component="h6"
                                        className={classes.relatedProductName}
                                    >
                                        {product.name}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        className={
                                            classes.relatedProductCompanyName
                                        }
                                    >
                                        {product.companyName}
                                    </Typography>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Container>
            )}
        </>
    )
}
