import { makeStyles, Theme, createStyles } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 72,
            textAlign: "center"
        },
        ovo: {
            width: 120,
            filter: "drop-shadow(0px 0px 24px #888888)"
        },
        title: {
            fontWeight: 900,
            marginTop: 16
        }
    }),
)