import { createStyles, makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        backIcon: {
            position: "fixed",
            zIndex: theme.zIndex.drawer + 2,
            width: 56,
            height: 56,
            padding: 16,
            margin: 16,
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "50%",
            color: "white",
            "&:hover": {
                cursor: "pointer",
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        productImageWrapper: {
            width: 300,
            height: 300,
            marginTop: 56,
        },
        productImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
        },
        productName: {
            marginTop: 32,
            fontWeight: 700,
        },
        subtitle: {
            marginTop: 16,
            opacity: 0.69,
        },
        workerInfo: {
            textAlign: "center",
        },
        workerImage: {
            width: 108,
            height: 108,
        },
        workerAge: {},
        weatherConditions: {
            fontWeight: 700,
            marginBottom: 56,
        },
        relatedProducts: {
            fontWeight: 700,
            marginBottom: 16,
        },
        relatedProductInfo: {
            "&:hover": {
                cursor: "pointer",
            },
        },
        relatedProductImageContainer: {
            width: "100%",
            height: 200,
        },
        relatedProductImage: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 4,
        },
        relatedProductName: {
            marginTop: 10,
        },
        relatedProductCompanyName: {},
    })
)
