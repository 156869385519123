import { makeStyles, Theme, createStyles } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 72,
            marginBottom: 0
        },
        title: {
            fontWeight: 700,
            marginBottom: 16
        },
        subtitle: {
            fontWeight: 400,
            opacity: 0.75
        }
    }),
)