import { ThemeProvider } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createHashHistory } from "history"
import React from "react"
import { HashRouter as Router, Route } from "react-router-dom"
import { MAINTENANCE_MODE } from "./Constants"
import AdminNewCompanyPage from "./pages/admin-new-company/AdminNewCompanyPage"
import AdminNewProductPage from "./pages/admin-new-product/AdminNewProductPage"
import AdminPage from "./pages/admin/AdminPage"
import LandingPage from "./pages/landing/LandingPage"
import LoginPage from "./pages/login/LoginPage"
import MaintenancePage from "./pages/maintenance/MaintenancePage"
import ProductPage from "./pages/product/ProductPage"
import ResetPasswordPage from "./pages/reset-password/ResetPasswordPage"
import SignupPage from "./pages/signup/SignupPage"
import theme from "./theme/Theme"

export const history = createHashHistory()

export default function App() {
	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<Router>
					{!MAINTENANCE_MODE ? (
						<>
							<Route exact path="/" component={LandingPage} />
							<Route exact path="/login" component={LoginPage} />
							<Route
								exact
								path="/signup"
								component={SignupPage}
							/>
							<Route
								exact
								path="/reset-password/:token"
								component={ResetPasswordPage}
							/>
							<Route
								exact
								path="/product/:productId"
								component={ProductPage}
							/>
							<Route exact path="/admin" component={AdminPage} />
							<Route
								exact
								path="/admin/new-company"
								component={AdminNewCompanyPage}
							/>
							<Route
								exact
								path="/admin/new-product"
								component={AdminNewProductPage}
							/>
						</>
					) : (
						<Route path="*" component={MaintenancePage} />
					)}
				</Router>
			</ThemeProvider>
		</>
	)
}
