import blue from "@material-ui/core/colors/blue"
import yellow from "@material-ui/core/colors/yellow"
import { createMuiTheme } from "@material-ui/core/styles"

export const ThemeConfiguration = {
	palette: {
		primary: blue,
		secondary: yellow
	}
}

export default createMuiTheme(ThemeConfiguration)
