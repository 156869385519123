import { createStyles, makeStyles, Theme } from "@material-ui/core"
import backgroundBlockchainImg from "../../../assets/images/background-blockchain.jpg"

export default makeStyles((theme: Theme) =>
    createStyles({
        background: {
            background: `linear-gradient(
                rgba(0, 0, 0, 0.7), 
                rgba(0, 0, 0, 0.7)
              ), url(${backgroundBlockchainImg})`,
            backgroundSize: "cover",
            boxShadow: "inset 0 0 24px rgba(0, 0, 0, 0.2)",
            color: "white",
            marginTop: 108,
            marginBottom: 108,
            paddingTop: 72,
            paddingBottom: 72,
        },
        root: {},
        title: {
            fontWeight: 700,
            marginBottom: 16,
        },
        subtitle: {
            fontWeight: 400,
        },
        scanLabel: {
            fontSize: 32,
            marginTop: 32,
        },
        buttons: {
            display: "inline",
            marginTop: "8px",
        },
        button: {
            marginTop: 16,
            marginRight: 16,
            color: "black",
            fontSize: 24,
            background: "white",
        },
        scanOverlay: {
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        sampleLabel: {
            textDecorationLine: "underline",
            textUnderlineOffset: "8px",
            "&:hover": {
                cursor: "pointer",
            },
        },
        nfcList: {
            margin: 0,
            padding: 0,
            paddingLeft: 16
        },
    })
)
