import { makeStyles, Theme, createStyles } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginTop: 36,
			marginBottom: 36,
		},
		logo: {
			height: 42,
			marginBottom: 32,
		},
	})
)
