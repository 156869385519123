import { createStyles, makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
	createStyles({
		root: {
			zIndex: theme.zIndex.drawer + 2
		},
		backArrow: {
			"&:hover": {
				cursor: "pointer"
			}
		}
	})
)
