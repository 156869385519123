import { makeStyles, Theme, createStyles, colors } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
    createStyles({
        background: {
            background:
                "linear-gradient(270deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,169,203,1) 100%)",
            boxShadow: "inset 0 0 24px rgba(0, 0, 0, 0.2)",
            color: "white",
            marginTop: 108,
            marginBottom: 108,
            paddingTop: 72,
            paddingBottom: 72,
        },
        root: {},
        title: {
            fontWeight: 700,
            marginBottom: 16,
        },
        subtitle: {
            fontWeight: 400,
        },
    })
)
