import { makeStyles, Theme, createStyles } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: 108,
			marginBottom: 56
		},
		title: {
			fontWeight: 700,
			textAlign: "left",
			marginBottom: 8
		},
		subtitle: {
			fontWeight: 400,
			textAlign: "left",
			marginBottom: 56
		},
		imgBox: {
			width: "100%",
			height: "100%",
			transition: "all 0.5s"
		},
		img: {
			width: "100%",
			height: "100%",
			objectFit: "contain",
			background: "white",
			padding: 16,
			borderRadius: 4
		}
	})
)
