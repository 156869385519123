import { makeStyles, Theme, createStyles } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: 108,
			marginBottom: 108
		},
		title: {
			fontWeight: 700,
			marginBottom: 16
		},
		textField: {
			width: "100%",
			marginTop: 24
		}
	})
)
