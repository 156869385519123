import { createStyles, makeStyles, Theme } from "@material-ui/core"
import backgroundPlantsImg from "../../../assets/images/background-plants.jpg"

export default makeStyles((theme: Theme) =>
	createStyles({
		root: {
			background: `url(${backgroundPlantsImg})`,
			backgroundSize: "cover",
			width: "100vw",
			height: "100vh",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		backIcon: {
			position: "fixed",
			zIndex: 999,
			width: 56,
			height: 56,
			padding: 16,
			margin: 16,
			background: "rgba(0, 0, 0, 0.6)",
			borderRadius: "50%",
			color: "white",
			"&:hover": {
				cursor: "pointer"
			}
		},
		contactForm: {
			padding: 56
		},
		title: {
			fontWeight: 700,
			marginTop: 24
		},
		subtitle: {
			marginTop: 16
		},
		email: {
			marginTop: 24
		},
		password: {
			marginTop: 16
		},
		buttonWrapper: {
			marginTop: 24,
			textAlign: "center"
		}
	})
)
