import { makeStyles } from "@material-ui/core/styles"
import { MOBILE_BREAKPOINT } from "../../../Constants"

export default makeStyles({
	heroBackground: {
		height: "100vh",
		width: "100vw",
		position: "absolute",
		background:
			"linear-gradient(-45deg, #116ba0, #1D99CC, #23a6d5, #30dcff)",
		backgroundSize: "400% 400%",
		animation: "$gradient 15s ease infinite",
		zIndex: 1,
		[`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
			height: "75vh",
		},
	},
	heroContent: {
		position: "relative",
		zIndex: 2,
		height: "100vh",
		color: "white",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		padding: "20vh",
		textAlign: "left",
		pointerEvents: "none",
		[`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
			padding: 36,
			height: "75vh",
		},
		[`@media (max-width: 768px)`]: {
			padding: 30
		},
		[`@media (max-width: 600px)`]: {
			padding: 16
		}
	},
	title: {
		fontSize: 92,
		fontWeight: 900,
		[`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
			fontSize: 72,
		}
	},
	subtitle: {
		fontWeight: 400,
		fontSize: 36,
		[`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
			fontSize: 26,
		}
	},
	"@keyframes gradient": {
		"0%": {
			backgroundPosition: "0% 50%"
		},
		"50%": {
			backgroundPosition: "100% 50%"
		},
		"100%": {
			backgroundPosition: "0% 50%"
		}
	}
})
