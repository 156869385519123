import React from "react"
import { Container, Typography } from "@material-ui/core"
import vivonImg from "../../assets/images/vivon.png"
import useStyles from "./styles/MaintenanceStyles"

export default function MaintenancePage() {
	const styles = useStyles()

	return (
		<Container className={styles.container}>
			<img src={vivonImg} className={styles.logo} alt="Vivon Logo" />
			<Typography variant="h6" component="h6">
				The website is currently in maintenance mode.
				<br />
				Please check back later.
			</Typography>
		</Container>
	)
}
