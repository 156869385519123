import {
    Dialog,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
} from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
// @ts-ignore
import { useCookie } from "@use-hook/use-cookie"
import clsx from "clsx"
import json2mq from "json2mq"
import React, { useEffect, useRef, useState } from "react"
import vivonImg from "../../../assets/images/vivon.png"
import { MOBILE_BREAKPOINT } from "../../../Constants"
import SmoothScroll from "../../../helpers/SmoothScroll"
import { ThemeConfiguration } from "../../../theme/Theme"
import useStyles from "../styles/LandingAppBarStyles"

const smoothScroll = new SmoothScroll()

export default function LandingAppBar() {
    const [jwt, setJwt] = useCookie("jwt", "undefined")
    const [isAdmin, setIsAdmin] = useCookie("jwt", "undefined")

    const [
        isHamburgerMenuDialogOpen,
        setIsHamburgerMenuDialogOpen,
    ] = useState<boolean>(false)

    const classes = useStyles()

    const isDesktop = useMediaQuery(
        json2mq({
            minWidth: MOBILE_BREAKPOINT,
        })
    )

    const appBarRef = useRef<HTMLElement>(null)
    const appBarLogoRef = useRef<HTMLImageElement>(null)
    const appBarNavAboutRef = useRef<HTMLElement>(null)
    const appBarNavTechnologiesRef = useRef<HTMLElement>(null)
    const appBarNavOvochainRef = useRef<HTMLElement>(null)
    const appBarNavPartnersRef = useRef<HTMLElement>(null)
    const appBarNavDemoRef = useRef<HTMLElement>(null)
    const appBarNavContactRef = useRef<HTMLElement>(null)
    const appBarNavHamburgerMenuRef = useRef<HTMLElement>(null)

    const appBarNavLoginRef = useRef<HTMLElement>(null)
    const appBarNavSignUpRef = useRef<HTMLButtonElement>(null)
    const appBarNavLogoutRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const onScrollListener = () => {
            const userHasScrolledDown = window.scrollY > 0
            if (appBarRef != null && appBarRef.current != null) {
                appBarRef.current.style.background = userHasScrolledDown
                    ? "white"
                    : "transparent"
                appBarRef.current.style.boxShadow = userHasScrolledDown
                    ? "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
                    : "none"
            }

            if (appBarLogoRef != null && appBarLogoRef.current != null) {
                appBarLogoRef.current.style.filter = userHasScrolledDown
                    ? "brightness(100%) invert(0%)"
                    : "brightness(0%) invert(100%)"
            }

            if (
                appBarNavAboutRef != null &&
                appBarNavAboutRef.current != null
            ) {
                appBarNavAboutRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"
            }

            if (
                appBarNavTechnologiesRef != null &&
                appBarNavTechnologiesRef.current != null
            ) {
                appBarNavTechnologiesRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"
            }

            if (
                appBarNavOvochainRef != null &&
                appBarNavOvochainRef.current != null
            ) {
                appBarNavOvochainRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"
            }

            if (
                appBarNavPartnersRef != null &&
                appBarNavPartnersRef.current != null
            ) {
                appBarNavPartnersRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"
            }

            if (appBarNavDemoRef != null && appBarNavDemoRef.current != null) {
                appBarNavDemoRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"
            }

            if (
                appBarNavContactRef != null &&
                appBarNavContactRef.current != null
            ) {
                appBarNavContactRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"
            }

            if (
                appBarNavHamburgerMenuRef != null &&
                appBarNavHamburgerMenuRef.current != null
            ) {
                appBarNavHamburgerMenuRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"

                appBarNavHamburgerMenuRef.current.style.border = userHasScrolledDown
                    ? "1px solid rgba(0, 0, 0, 0.23)"
                    : "1px solid rgba(255, 255, 255, 0.23)"
            }

            if (
                appBarNavLoginRef != null &&
                appBarNavLoginRef.current != null
            ) {
                appBarNavLoginRef.current.style.color = userHasScrolledDown
                    ? "black"
                    : "white"
            }

            if (
                appBarNavLogoutRef != null &&
                appBarNavLogoutRef.current != null
            ) {
                appBarNavLogoutRef.current.style.color = userHasScrolledDown
                    ? "white"
                    : "black"
                appBarNavLogoutRef.current.style.background = userHasScrolledDown
                    ? ThemeConfiguration.palette.primary[500]
                    : "white"
            }

            if (
                appBarNavSignUpRef != null &&
                appBarNavSignUpRef.current != null
            ) {
                appBarNavSignUpRef.current.style.color = userHasScrolledDown
                    ? "white"
                    : "black"
                appBarNavSignUpRef.current.style.background = userHasScrolledDown
                    ? ThemeConfiguration.palette.primary[500]
                    : "white"
            }
        }

        window.addEventListener("scroll", onScrollListener)
        return () => {
            window.removeEventListener("scroll", onScrollListener)
        }
    }, [])

    // Update AppBar when reloading and it didn't scroll to top
    useEffect(() => {
        window.dispatchEvent(new CustomEvent("scroll"))
    }, [])

    return (
        <>
            <AppBar position="fixed" className={classes.appBar} ref={appBarRef}>
                <Toolbar>
                    <div style={{ display: "flex", flexGrow: 1 }}>
                        <img
                            className={classes.logo}
                            ref={appBarLogoRef}
                            src={vivonImg}
                            onClick={() => smoothScroll.scrollTo("top")}
                        />
                    </div>
                    {isDesktop ? (
                        <>
                            <Typography
                                variant="h6"
                                className={clsx(
                                    classes.menuButton,
                                    classes.menuButtonLink
                                )}
                                ref={appBarNavAboutRef}
                                onClick={() =>
                                    smoothScroll.scrollTo("about", -124, null)
                                }
                            >
                                Chi Siamo
                            </Typography>
                            <Typography
                                variant="h6"
                                className={clsx(
                                    classes.menuButton,
                                    classes.menuButtonLink
                                )}
                                ref={appBarNavTechnologiesRef}
                                onClick={() =>
                                    smoothScroll.scrollTo(
                                        "technologies",
                                        -124,
                                        null
                                    )
                                }
                            >
                                Tecnologie
                            </Typography>
                            <Typography
                                variant="h6"
                                className={clsx(
                                    classes.menuButton,
                                    classes.menuButtonLink
                                )}
                                ref={appBarNavOvochainRef}
                                onClick={() =>
                                    smoothScroll.scrollTo(
                                        "ovochain",
                                        -124,
                                        null
                                    )
                                }
                            >
                                Ovochain
                            </Typography>
                            <Typography
                                variant="h6"
                                className={clsx(
                                    classes.menuButton,
                                    classes.menuButtonLink
                                )}
                                ref={appBarNavPartnersRef}
                                onClick={() =>
                                    smoothScroll.scrollTo(
                                        "partners",
                                        -124,
                                        null
                                    )
                                }
                            >
                                Partners
                            </Typography>
                            <Typography
                                variant="h6"
                                className={clsx(
                                    classes.menuButton,
                                    classes.menuButtonLink
                                )}
                                ref={appBarNavDemoRef}
                                onClick={() =>
                                    smoothScroll.scrollTo("demo", -56, null)
                                }
                            >
                                Demo
                            </Typography>
                            <Typography
                                variant="h6"
                                className={clsx(
                                    classes.menuButton,
                                    classes.menuButtonLink
                                )}
                                ref={appBarNavContactRef}
                                onClick={() =>
                                    smoothScroll.scrollTo("contact", -124, null)
                                }
                            >
                                Contatti
                            </Typography>
                        </>
                    ) : (
                        <Button
                            variant="outlined"
                            className={classes.hamburgerMenu}
                            buttonRef={appBarNavHamburgerMenuRef}
                            onClick={() => setIsHamburgerMenuDialogOpen(true)}
                        >
                            Menu
                        </Button>
                    )}
                    {/* {jwt != "undefined" ? (
					<>
						<Button
							color="inherit"
							variant="contained"
							className={classes.menuButtonSignUpLink}
							ref={appBarNavLogoutRef}
							onClick={() => {
								setJwt(undefined)
								setIsAdmin(undefined)
								window.location.reload(true)
							}}
						>
							Logout
						</Button>
					</>
				) : (
					<>
						<Typography
							variant="h6"
							className={clsx(classes.menuButton, classes.menuButtonLink)}
							ref={appBarNavLoginRef}
							onClick={() => history.push("/login")}
						>
							Login
						</Typography>
						<Button
							color="inherit"
							variant="contained"
							className={classes.menuButtonSignUpLink}
							ref={appBarNavSignUpRef}
							onClick={() => history.push("/signup")}
						>
							Sign Up
						</Button>
					</>
				)} */}
                </Toolbar>
            </AppBar>

            <HamburgerMenuDialog
                handleClose={() => {
                    setIsHamburgerMenuDialogOpen(false)
                }}
                open={isHamburgerMenuDialogOpen}
                onSelected={(itemId) => {
                    smoothScroll.scrollTo(itemId, -124, null)
                    setIsHamburgerMenuDialogOpen(false)
                }}
            />
        </>
    )
}

const HamburgerMenuDialog = (props: {
    handleClose: () => void
    open: boolean
    onSelected: (id: string) => void
}) => {
    const MENU_ITEMS: Array<{ id: string; label: string }> = [
        { id: "about", label: "About" },
        { id: "technologies", label: "Technologies" },
        { id: "ovochain", label: "Ovochain" },
        { id: "partners", label: "Partner" },
        { id: "demo", label: "Demo" },
        { id: "contact", label: "Contact" },
    ]

    return (
        <Dialog onClose={props.handleClose} open={props.open}>
            <List>
                {MENU_ITEMS.map((item) => (
                    <ListItem
                        button
                        onClick={() => props.onSelected(item.id)}
                        key={item.id}
                    >
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    )
}
