export default class SmoothScrolling {
    private timer: any

    stop() {
        clearTimeout(this.timer)
    }

    scrollTo(id: string, additionalPadding: number = 0, callback: any = null) {
        var settings = {
            duration: 1000,
            easing: {
                outQuint: (x: any, t: any, b: any, c: any, d: any) => {
                    return c * ((t = t / d - 1) * t * t * t * t + 1) + b
                },
            },
        }
        let percentage = 0
        let startTime = 0
        let node: any = document.getElementById(id)
        let nodeTop = node.offsetTop
        let nodeHeight = node.offsetHeight
        let body = document.body
        let html = document.documentElement
        let height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        )
        let windowHeight = window.innerHeight
        let offset = window.pageYOffset
        let delta = nodeTop - offset
        let bottomScrollableY = height - windowHeight
        let targetY =
            bottomScrollableY < delta
                ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
                : delta

        targetY += additionalPadding

        startTime = Date.now()
        percentage = 0

        if (this.timer) {
            clearInterval(this.timer)
        }

        const step = () => {
            var yScroll
            var elapsed = Date.now() - startTime

            if (elapsed > settings.duration) {
                clearTimeout(this.timer)
            }

            percentage = elapsed / settings.duration

            if (percentage > 1) {
                clearTimeout(this.timer)

                if (callback) {
                    callback()
                }
            } else {
                yScroll = settings.easing.outQuint(
                    0,
                    elapsed,
                    offset,
                    targetY,
                    settings.duration
                )
                window.scrollTo(0, yScroll)
                this.timer = setTimeout(step, 10)
            }
        }

        this.timer = setTimeout(step, 10)
    }
}
