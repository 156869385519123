import { makeStyles, Theme, createStyles } from "@material-ui/core"
import { MOBILE_BREAKPOINT } from "../../../Constants"

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            boxShadow: "none",
            backgroundColor: "transparent",
            transition: "0.5s all"
        },
        logo: {
            userSelect: "none",
            height: 30,
            "&:hover": {
                cursor: "pointer"
            }
        },
        menuButton: {
            userSelect: "none",
            marginRight: theme.spacing(4),
            fontSize: "16px",
            [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
                display: "none"
            },
            "&:hover": {
                cursor: "pointer"
            }
        },
        menuButtonLink: {
            color: "white",
            textDecoration: "none",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline"
            }
        },
        menuButtonSignUpLink: {
            color: "black",
            transition: "all 0.5s"
        },
        hamburgerMenu: {
            transition: "all 0.5s"
        }
    }),
)